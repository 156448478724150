import React from "react";
import "./BeatportEmbed.css";

type Props = {
	url: string;
};

type State = {};

class BeatportEmbed extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};
	}

	getUrlId(url: string) {
		let urlId = url.replace(/(?:[^\/]*\/){5}/, "");

		if (urlId.charAt(urlId.length - 1) === '/') {
			urlId = urlId.substr(0, urlId.length - 1);
		}

		return urlId;
	}

	render() {
		const urlId = this.getUrlId(this.props.url);

		return (
			<div className="beatport-player">
				<iframe
					title={"beatport-" + urlId}
					src={"https://embed.beatport.com/?id=" + urlId + "&type=track"}
					width="100%"
					height="110px"
					frameBorder={0}
					scrolling="no"
				>
				</iframe>
			</div>
		);
	}
}

export default BeatportEmbed;






