import React from "react";
import { observer } from "mobx-react";
import { Strings } from "../Utils/StringCollection";
import iconWhatsApp from "../../images/icons/logo_whatsapp@4x.png";


type Props = {
	pageLink: string;
};

type State = {
};

@observer
class ShareWhatsApp extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
		};
	}

	componentDidMount() { }


	render() {

		return (
			<a
				href={"whatsapp://send?text=" + this.props.pageLink}
				data-action="share/whatsapp/share"
				className="share-link whatsapp cursor-pointer relative flex items-center"
			>
				<img src={`/assets${iconWhatsApp}`} alt="Icon WhatsApp"></img>
				<span className="span-underline w-full md:w-auto flex flex-col relative items-start h-full justify-center">
					{Strings.WhatsApp}
				</span>
			</a>
		);
	}
}

export default ShareWhatsApp;
