import React from "react";
import { Strings } from "../Utils/StringCollection";

type Props = {
	message: string;
};


class NoTrack extends React.Component<Props> {
	render() {
		return (
			<div className="no-event-container flex flex-col text-light text-h3 items-center pointer-events-none">
				<p className="m-0 pl-4 pr-4 pt-40 text-center w-full md:w-1/2">{this.props.message}</p>
			</div>
		);
	}
}

export default NoTrack;
