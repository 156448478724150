import * as React from 'react'
import TrackLink from './TrackLink';

import IconShowAll from "../../images/icons/show_all.png";
import closeIcon from "../../images/icons/close.png";
import { Strings } from '../Utils/StringCollection';
import { LastPlay, Track } from '../Types/Track';
import _ from 'lodash';


type TrackInformation = {
    platform: string;
    name: string;
    url: string;
};


function getTrackLinks(lastPlay: LastPlay) {
    let trackLinks: Array<TrackInformation> = [];

    if ("beatportUrl" in lastPlay && lastPlay.beatportUrl) {
        trackLinks.push({
            platform: "beatport",
            url: lastPlay.beatportUrl,
            name: Strings.Beatport,
        });
    }

    if ("spotifyUrl" in lastPlay && lastPlay.spotifyUrl) {
        trackLinks.push({
            platform: "spotify",
            url: lastPlay.spotifyUrl,
            name: Strings.Spotify,
        });
    }

    if ('appleUrl' in lastPlay && lastPlay.appleUrl) {
        trackLinks.push({
            platform: "appleMusic",
            url: lastPlay.appleUrl,
            name: Strings.AppleMusic,
        });
    }

    if ("amazonUrl" in lastPlay && lastPlay.amazonUrl) {
        trackLinks.push({
            platform: "amazon",
            url: lastPlay.amazonUrl,
            name: Strings.AmazonMusic,
        });
    }

    if ("youtubeUrl" in lastPlay && lastPlay.youtubeUrl) {
        trackLinks.push({
            platform: "youtube",
            url: lastPlay.youtubeUrl,
            name: Strings.YoutubeMusic,
        });
    }

    if ("deezerUrl" in lastPlay && lastPlay.deezerUrl) {
        trackLinks.push({
            platform: "deezer",
            url: lastPlay.deezerUrl,
            name: Strings.Deezer,
        });
    }

    return trackLinks;
}

export const TrackLinks = ({ track }: { track: Track }) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const handleClickToggleDropdownContent = () => {
        setDropdownOpen(!dropdownOpen);

        if (!dropdownOpen) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    };

    const trackLinks = track.lastPlay
        ? getTrackLinks(track.lastPlay)
        : null;

    return (<>
        {/* Desktop */}
        <div
            className="hidden md:flex url-container items-center justify-end"
            style={{ width: "20%" }
            }
        >
            {trackLinks &&
                _.slice(trackLinks, 0, trackLinks.length === 5 ? 5 : 4).map((tLink, index) => {
                    return (
                        <TrackLink
                            key={index}
                            name={tLink.name}
                            url={tLink.url}
                            platform={tLink.platform}
                        />
                    );
                })}
            {
                trackLinks && trackLinks.length > 5 && (
                    <div className="dropdown relative">
                        <div
                            className="dropdown-icon cursor-pointer relative"
                            onClick={() => setDropdownOpen(!dropdownOpen)}>

                            <img
                                src={`/assets${IconShowAll}`}
                                alt="Icon Show All"
                            ></img>
                        </div>
                        <div
                            className={`dropdown-content bg-greyUltraDark absolute w-full rounded-md ${dropdownOpen ? "open" : ""
                                }`}
                        >
                            {_.slice(trackLinks, 4).map((tLink, index) => {
                                return (
                                    <TrackLink
                                        key={index}
                                        dropdown={true}
                                        name={tLink.name}
                                        url={tLink.url}
                                        platform={tLink.platform}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )
            }
        </div >

        {/* Mobile */}
        < div
            className="flex md:hidden url-container items-center justify-end"
            style={{ width: "24px" }}
        >
            {trackLinks && trackLinks.length >= 1 && (
                <div className="dropdown relative w-full">
                    <div
                        className="dropdown-icon cursor-pointer"
                        onClick={handleClickToggleDropdownContent}
                    >
                        <img
                            src={`/assets${IconShowAll}`}
                            alt="Icon Show All"
                        ></img>
                    </div>
                    <div
                        className={`dropdown-container ${dropdownOpen ? "open" : ""
                            }`}
                    >
                        <div
                            className={`dropdown-content dropdown-content-mobile bg-dark ${dropdownOpen ? "open" : ""
                                }`}
                        >
                            <div className="dropdown-header flex justify-end items-center p-4 bg-greyUltraDark border border-r-0 border-l-0 border-greyVeryDark">
                                <button
                                    className="bg-transparent p-0 flex justify-center items-center border-none"
                                    onClick={handleClickToggleDropdownContent}
                                >
                                    <img
                                        src={`/assets${closeIcon}`}
                                        alt="Close Icon"
                                    ></img>
                                </button>
                            </div>
                            {trackLinks.map((tLink, index) => {
                                return (
                                    <TrackLink
                                        key={index}
                                        dropdown={true}
                                        name={tLink.name}
                                        url={tLink.url}
                                        platform={tLink.platform}
                                        openOnPlatform={Strings.OpenOnPlatform}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div ></>)
}