import { makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import PauseIcon from "../../images/icons/ic_player_pause@4x.png";
import PlayIcon from "../../images/icons/ic_player_play@4x.png";
import { Track } from "../Types/Track";
import uiFunction from "../Utils/HelperFunctions";
import BeatportEmbed from "./BeatportEmbed";
import { TrackLinks } from "./TrackLinks";

type Props = {
	track: Track;
	current: string;
	isOpen: boolean;
	onClick: any;
};

type State = {
	dropdownOpen: boolean;
	touch: boolean;
	showBeatportPlayer: boolean;
};
@observer
class TrackElement extends React.Component<Props, State> {
	@observable trackWrapperTrack: any;
	@observable trackNameTrack: any;
	@observable trackWrapperArtist: any;
	@observable trackNameArtist: any;
	@observable trackWrapperLabel: any;
	@observable trackNameLabel: any;
	@observable animateTitleTrack: boolean = false;
	@observable animateTitleArtist: boolean = false;
	@observable animateTitleLabel: boolean = false;

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
			dropdownOpen: false,
			touch: false,
			showBeatportPlayer: false,
		};

		makeObservable(this, {
			animateTitleTrack: observable,
			animateTitleArtist: observable,
			animateTitleLabel: observable,
			trackWrapperTrack: observable,
			trackNameTrack: observable,
			trackWrapperArtist: observable,
			trackNameArtist: observable,
			trackWrapperLabel: observable,
			trackNameLabel: observable,
		});

		this.trackWrapperTrack = React.createRef();
		this.trackNameTrack = React.createRef();
		this.trackWrapperArtist = React.createRef();
		this.trackNameArtist = React.createRef();
		this.trackWrapperLabel = React.createRef();
		this.trackNameLabel = React.createRef();
	}

	componentDidMount() {
		this.animateTitleTrack = uiFunction.checkTrackNameAnimation(
			this.trackWrapperTrack,
			this.trackNameTrack
		);
		this.animateTitleArtist = uiFunction.checkTrackNameAnimation(
			this.trackWrapperArtist,
			this.trackNameArtist
		);
		this.animateTitleLabel = uiFunction.checkTrackNameAnimation(
			this.trackWrapperLabel,
			this.trackNameLabel
		);
	}

	componentDidUpdate() {
		this.animateTitleTrack = uiFunction.checkTrackNameAnimation(
			this.trackWrapperTrack,
			this.trackNameTrack
		);
		this.animateTitleArtist = uiFunction.checkTrackNameAnimation(
			this.trackWrapperArtist,
			this.trackNameArtist
		);
		this.animateTitleLabel = uiFunction.checkTrackNameAnimation(
			this.trackWrapperLabel,
			this.trackNameLabel
		);
	}

	handleTouch = (e: any) => {
		this.setState({ touch: true });
	};

	cancelTouch = (e: any) => {
		this.setState({ touch: false });
	};

	toggleDropdownContent = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });

		if (!this.state.dropdownOpen) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
	};

	render() {
		const track = this.props.track;

		return (
			<>
				{this.props.track.status === "featured" && (
					<div style={{ top: 14 }} className="relative flex justify-center">
						<div className="relative inline-block w-auto h-auto text-black px-3 bg-featured whitespace-no-wrap transform -skew-x-12">
							<div className="transform skew-x-12 text-dark font-semibold">
								track feature
							</div>
						</div>
					</div>
				)}
				<div
					onClick={this.props.onClick}
					className={`track-row featured-track container mx-auto flex flex-col text-greyLightHeader  ${
						this.props.track.status === "featured"
							? "border-2 border-featured rounded-2xl mx-4 w-11/12"
							: "border border-greyVeryDark border-r-0 border-l-0"
					}  ${this.props.current}`}
				>
					<div className="track-info-container w-full flex items-center">
						{track.lastPlay?.cover && (
							<div
								onClick={() => {
									this.setState({
										showBeatportPlayer: !this.state.showBeatportPlayer,
									});
								}}
								className={
									"image-container relative flex justify-center items-center " +
									(this.state.showBeatportPlayer ? "open" : "close") +
									" " +
									(this.props.track.lastPlay?.beatportUrl
										? "beatport"
										: "no-beatport")
								}
							>
								{this.props.track.lastPlay?.beatportUrl &&
									(this.state.showBeatportPlayer && this.props.isOpen ? (
										<img
											className="icon"
											src={`/assets${PauseIcon}`}
											alt="Pause Icon"
										/>
									) : (
										<img
											className="icon"
											src={`/assets${PlayIcon}`}
											alt="Play Icon"
										/>
									))}
								<img
									className="cover"
									src={track.lastPlay?.cover}
									alt={`${track.lastPlay?.artistName}`}
								/>
							</div>
						)}
						<div className="text-container-name-artist flex flex-col md:flex-row">
							<div
								className={`text-container text-container-name ${
									this.state.touch ? "touch" : ""
								}`}
								onTouchStart={this.handleTouch}
								onTouchEnd={this.cancelTouch}
								onTouchCancel={this.cancelTouch}
								style={{
									display: "flex",
									alignItems: "center",
									marginRight: "15px",
								}}
							>
								{track.lastPlay?.name && (
									<div
										className={"scrolling-container"}
										ref={this.trackWrapperTrack}
									>
										<p
											className={`title text-light font-medium m-0 inline-flex ${
												this.animateTitleTrack ? "animate" : ""
											}`}
											ref={this.trackNameTrack}
										>
											{track.lastPlay?.name}
											{track.lastPlay?.mix && (
												<span>({track.lastPlay?.mix})</span>
											)}
											{track.lastPlay.label && (
												<span className="flex md:hidden">
													[{track.lastPlay?.label}]
												</span>
											)}
										</p>
									</div>
								)}
							</div>

							<div
								className={`text-container text-container-artist ${
									this.state.touch ? "touch" : ""
								}`}
								onTouchStart={this.handleTouch}
								onTouchEnd={this.cancelTouch}
								onTouchCancel={this.cancelTouch}
								style={{ marginRight: "15px" }}
							>
								{track.lastPlay?.artistName && (
									<div
										className={"scrolling-container"}
										ref={this.trackWrapperArtist}
									>
										<p
											className={`artist m-0 inline-flex ${
												this.animateTitleArtist ? "animate" : ""
											}`}
											ref={this.trackNameArtist}
										>
											{track.lastPlay?.artistName}
										</p>
									</div>
								)}
							</div>
						</div>
						<div
							className={`text-container text-container-label hidden md:flex ${
								this.state.touch ? "touch" : ""
							}`}
							onTouchStart={this.handleTouch}
							onTouchEnd={this.cancelTouch}
							onTouchCancel={this.cancelTouch}
							style={{ marginRight: "15px" }}
						>
							{track.lastPlay?.label && (
								<div
									className={"scrolling-container"}
									ref={this.trackWrapperLabel}
								>
									<p
										className={`label whitespace-nowrap m-0 inline-flex ${
											this.animateTitleLabel ? "animate" : ""
										}`}
										ref={this.trackNameLabel}
									>
										{track.lastPlay?.label}
									</p>
								</div>
							)}
						</div>

						<TrackLinks track={track} />
					</div>
					<div
						className={
							"beatport-container " +
							(this.state.showBeatportPlayer && this.props.isOpen
								? "show"
								: "hide")
						}
					>
						{this.props.track.lastPlay?.beatportUrl &&
							this.state.showBeatportPlayer &&
							this.props.isOpen && (
								<BeatportEmbed url={this.props.track.lastPlay.beatportUrl} />
							)}
					</div>
				</div>
			</>
		);
	}
}

export default TrackElement;
