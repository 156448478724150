import { observer } from "mobx-react";
import React from "react";
import { Stream } from "../Types/Stream";
import _ from "lodash";
import { StreamsStore } from "../Stores/StreamsStore";
import { Strings } from "../Utils/StringCollection";

type Props = {
	currentStream?: Stream,
	artistId: string,
	artistName: string,
	artistImage?: string,
	streamsStore: StreamsStore
};

type State = {};

@observer
class HeaderCurrentArtist extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};
	}

	openFbPage = (url: string) => {
		window.open(url, "_blank")
	}

	isNowLive(start: string, end: string) {
		let now = new Date();
		if (start !== undefined && end !== undefined) {
			let startTime = new Date(start);
			let endTime = new Date(end);

			if (startTime <= now && endTime >= now) {
				return true;
			} else {
				return false;
			}
		}
	}

	render() {
		let startDate = this.props.currentStream?.start_time_utc;
		let endDate = this.props.currentStream?.end_time_utc;

		return (
			<div className="header-current-artist-container w-full md:w-1/2 flex items-center bg-greyUltraDark md:bg-transparent">
				<div style={{ display: "flex" }}>
					{this.props.artistImage &&
						<div className="header-current-artist">
							<img className="artist-image rounded-full object-cover" src={this.props.artistImage} alt={this.props.artistName}></img>
						</div>
					}
					<div className="flex flex-col justify-center">
						{startDate && endDate && this.isNowLive(startDate, endDate) === true &&
							<p className="live flex flex-row items-center text-primary">{Strings.NowLive}</p>
						}
						{this.props.artistName &&
							<h3 className="font-palanquin font-bold text-h3 m-0">{this.props.artistName}</h3>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default HeaderCurrentArtist;
