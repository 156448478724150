import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';


// DEV
// const config = {
// 	apiKey: "AIzaSyBxJuF_mMvUdX9WEOt9-22kpT4C0D32Gog",
// 	authDomain: "seeqnc-development.firebaseapp.com",
// 	databaseURL: "https://seeqnc-development.firebaseio.com",
// 	projectId: "seeqnc-development",
// 	storageBucket: "seeqnc-development.appspot.com",
// 	messagingSenderId: "391754866267",
// 	appId: "1:391754866267:web:82176b750cf292f874297e",
// 	measurementId: "G-XBY1GWJV32"
// }

// LIVE
const config = {
	apiKey: "AIzaSyARFUTEc29QGCCfcEe1RFJF7RjHW5yCjJA",
	authDomain: "seeqnc.firebaseapp.com",
	databaseURL: "https://seeqnc.firebaseio.com",
	projectId: "seeqnc",
	storageBucket: "seeqnc.appspot.com",
	messagingSenderId: "399597706483",
	appId: "1:399597706483:android:843887e2f82c9407",
	measurementId: "G-XBY1GWJV32"
}

function initFirebase() {
	const f = !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
	return f
}


export type FirebaseProvider = {
	firebaseApp: firebase.app.App
	firestore: firebase.firestore.Firestore
}

export const firebaseProvider: FirebaseProvider = {
	firebaseApp: initFirebase(),
	firestore: firebase.firestore(),
}
