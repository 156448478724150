import { head } from "lodash";
import { makeObservable, observable, configure } from "mobx";
import { firebaseProvider } from "../Provider/firebaseProvider";
import { Track } from "../Types/Track";

configure({ enforceActions: "never" });

class FeedStore {
	isLoading: boolean = false;
	tracklist: Array<Track> = [];
	currentArtistId: string;
	newestTrack?: Track = undefined;
	selectedArtistId: string = "";
	noFeed: boolean = true;
	loaded: boolean = false;

	constructor(artistIds: string[], currentArtistId: string, eventStart: string) {
		this.currentArtistId = currentArtistId;
		makeObservable(this, {
			isLoading: observable,
			tracklist: observable,
			currentArtistId: observable,
			newestTrack: observable,
			selectedArtistId: observable,
			noFeed: observable,
			loaded: observable,
		});

		if (artistIds.length > 0) {
			firebaseProvider.firestore.collection("live-feed-captures")
				.where("timestamp", ">=", eventStart)
				// mehrere Artist Ids, die in Lineup vorkommen
				.where("lastPlay.performingArtistID", "in", artistIds)
				.orderBy("timestamp", "desc")
				.onSnapshot(observer => {
					this.tracklist = observer.docs.map(doc => {
						this.isLoading = false;
						this.loaded = true;
						return { id: doc.id, ...doc.data() }
					});
					this.newestTrack = head(this.tracklist);
					this.noFeed = false;
				});
		}
	}
}
export { FeedStore }
