import { makeObservable, observable, toJS } from "mobx";
import { firebaseProvider } from "../Provider/firebaseProvider";
import { Stream } from "../Types/Stream";
import _ from "lodash";


class StreamsStore {
	isLoading: boolean = false;
	loaded: boolean = false;
	streams?: Array<Stream>;
	artistId: string;
	currentStream?: Stream;
	currentStreamId?: string;
	previousStreams: Stream[] = [];
	futureStreams: Stream[] = [];

	constructor(artistId: string) {
		this.artistId = artistId;
		makeObservable(this, {
			isLoading: observable,
			streams: observable,
			currentStream: observable,
			currentStreamId: observable,
			previousStreams: observable,
			futureStreams: observable,
			loaded: observable,
		});

		this.loadStreamsByArtistId();
	}

	calculateCurrentStream(streams: Stream[]) {
		let now = new Date();
		let stream;

		streams.filter((value) => {
			if (value.lineup_utc && value.start_time_utc !== undefined && value.end_time_utc !== undefined) {
				let startTime = new Date(value.start_time_utc);
				let endTime = new Date(value.end_time_utc);

				if (endTime < now) {
					stream = this.previousStreams && this.previousStreams.length > 0 ? _.last(this.previousStreams) : undefined;
					this.currentStream = stream;
					this.currentStreamId = value.documentId
				} else if (startTime <= now && endTime >= now) {
					stream = value;
					this.currentStream = stream;
					this.currentStreamId = value.documentId
				}
			} else {
				stream = undefined;
			}
		})

		// return this.streams && this.streams.length > 0 ? _.head(streamsStore.streams) : undefined;
	}

	calculatePreviousStreams(streams: Stream[]) {
		let now = new Date();
		let prevStreams: Stream[] = [];

		streams.filter((value) => {
			if (value.lineup_utc && value.end_time_utc !== undefined) {
				let endTime = new Date(value.end_time_utc);

				if (endTime < now) {
					prevStreams.push(value);
				}
			}
		})

		this.previousStreams = prevStreams;

		// return this.streams && this.streams.length > 1 ? _.tail(streamsStore.streams) : undefined;
	}

	calculateFutureStreams(streams: Stream[]) {
		let now = new Date();
		let futureStreams: Stream[] = [];

		streams.filter((value) => {
			if (value.lineup_utc && value.end_time_utc !== undefined && value.start_time_utc !== undefined) {
				let endTime = new Date(value.end_time_utc);
				let startTime = new Date(value.start_time_utc);

				if (startTime > now && endTime > now) {
					futureStreams.push(value);
				}
			}
		})

		this.futureStreams = futureStreams;

		// return this.streams && this.streams.length > 1 ? _.tail(streamsStore.streams) : undefined;
	}

	async loadStreamsByArtistId() {
		this.isLoading = true;

		return firebaseProvider.firestore.collection("scheduled-streams")
			// .doc("19297198192").collection("streams")
			.doc(this.artistId).collection("streams")
			.orderBy("start_time_utc", "asc") //change to asc when using live content
			.get()
			.then(response => {
				var streamResults: Array<Stream> = [];
				response.forEach(document => {
					const result = { ...document.data(), ['documentId']: document.ref.id }

					if (result) {
						streamResults.push(result);
					}
				});
				this.streams = streamResults;

				this.calculateFutureStreams(this.streams);
				this.calculatePreviousStreams(this.streams);
				this.calculateCurrentStream(this.streams);

				this.isLoading = false;
				this.loaded = true;
				return this.streams;
			})
	}
}

export { StreamsStore }