import { observer } from "mobx-react";
import React from "react";
import { FeedStore } from "../Stores/FeedStore";
import { makeObservable, observable } from "mobx";
import { Strings } from "../Utils/StringCollection";
import logo from "../../images/icons/seeqnc_logo.svg";

type Props = {};

type State = {};

@observer
class HeaderLogoClaim extends React.Component<Props, State> {
	@observable currentFeed?: FeedStore;

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};

		makeObservable(this, {
			currentFeed: observable,
		});
	}

	render() {
		return (
			<>
				<div className="header-logo-claim relative w-full md:w-auto flex justify-start md:justify-end items-center overflow-hidden bg-transparent">
					<div className="hidden md:block round-container bg-greyVeryDark rounded-full relative"></div>
					<div className="logo-claim-container absolute h-full w-full flex items-center justify-start md:justify-center">
						<img src={`/assets${logo}`} alt="Seeqnc Logo"></img>
						<div>
							<h1 className="text font-palanquin m-0 text-base md:text-h3 text-primary md:text-light font-semibold md:font-bold">{Strings.HeaderBrand}</h1>
							<p className="font-palanquin m-0 text-greyLightHeader text-smallBase md:text-base font-medium md:font-normal">{Strings.HeaderClaim}</p>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default HeaderLogoClaim;
