import React from 'react';
import { makeObservable, observable } from "mobx";
import { observer } from 'mobx-react';
import HeaderCurrentArtist from './HeaderCurrentArtist';
import HeaderLogoClaim from './HeaderLogoClaim';
import { Stream } from '../Types/Stream';
import { FeedStore } from '../Stores/FeedStore';
import { StreamsStore } from '../Stores/StreamsStore';
import HeaderMoreButton from './HeaderMoreButton';
import { SpotifyStore } from '../Stores/SpotifyStore';

type Props = {
	artistId: string,
	artistName: string,
	artistImage?: string,
	currentStream?: Stream,
	streamsStore: StreamsStore,
	currentSpotify: SpotifyStore,
	streamStore: StreamsStore,
};

type State = {
	isLoading: boolean;
	tabIndex: number;
}

@observer
class HeaderArtistWrapper extends React.Component<Props, State> {
	@observable currentFeed?: FeedStore;
	isLoading: boolean = true;

	constructor(props: Props) {
		super(props);
		this.state = {
			isLoading: true,
			tabIndex: 0,
		}

		makeObservable(this, {
			isLoading: observable,
			currentFeed: observable,
		});

	}

	render() {
		return (
			<div className="header-artist-wrapper">
				{/* Desktop */}
				<div className="hidden md:block header-react w-full fixed bg-greyUltraDark">
					<div className="header-artist-container flex justify-between items-center text-light">
						<HeaderCurrentArtist
							currentStream={this.props.currentStream}
							artistId={this.props.artistId}
							artistName={this.props.artistName}
							artistImage={this.props.artistImage}
							streamsStore={this.props.streamsStore}
						/>
						<div className="w-full md:w-1/2 flex justify-end items-center">
							<HeaderMoreButton
								currentStream={this.props.currentStream}
								currentSpotify={this.props.currentSpotify}
								currentStreamId={this.props.streamStore.currentStreamId}
							/>
							<HeaderLogoClaim />
						</div>
					</div>
				</div>
				{/* Mobile */}
				<div className="block md:hidden header-react-mobile">
					<div className="header-artist-container flex flex-col md:flex-row md:justify-between md:items-center text-light">
						<div id="fixed-container" className="fixed-container fixed flex w-full bg-greyUltraDark items-center pr-6">
							<HeaderLogoClaim />
							<HeaderMoreButton
								currentStream={this.props.currentStream}
								currentSpotify={this.props.currentSpotify}
								currentStreamId={this.props.streamStore.currentStreamId}
							/>
						</div>
						<HeaderCurrentArtist
							currentStream={this.props.currentStream}
							artistId={this.props.artistId}
							artistName={this.props.artistName}
							artistImage={this.props.artistImage}
							streamsStore={this.props.streamsStore}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default HeaderArtistWrapper;
