import React from "react";
import { Strings } from "../Utils/StringCollection";
import logo from "../../images/icons/seeqnc_logo.svg";

type Props = {};

type State = {};

class Footer extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};
	}

	render() {

		return (
			<footer
				className="container mx-auto flex flex-row justify-between items-center pt-5 pb-10"
			>
				<img style={{ width: "24px", height: "24px" }} src={`/assets${logo}`} alt="Seeqnc Logo" />
				<div>
					<p className="text-greyLightHeader">{Strings.Copyright}</p>
				</div>
			</footer>
		);
	}
}

export default Footer;




