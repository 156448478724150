import React from "react";
import logoBeatport from "../../images/icons/logo_beatport.svg";
import logoAmazon from "../../images/icons/logo_amazon_music.png";
import logoDeezer from "../../images/icons/logo_deezer_round.png";
import logoSpotify from "../../images/icons/logo_spotify.svg";
import logoYoutube from "../../images/icons/logo_youtube_music_2.png";
import logoAppleMusic from "../../images/icons/logo_apple_music_2.png";
import openIcon from "../../images/icons/ic_external@2x.png";

type Props = {
	url: string;
	platform: string;
	name: string;
	dropdown?: boolean;
	openOnPlatform?: string;
};

type State = {};

class TrackLink extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};
	}

	componentDidMount() { }

	render() {
		const link = this.props.url;
		const platform = this.props.platform;
		const dropdown = this.props.dropdown;
		const name = this.props.name;
		const openOnPlatform = this.props.openOnPlatform;

		return (
			<a
				href={link}
				target="_blank"
				rel="noreferrer"
				className={`tracklink flex relative font-medium text-light no-underline ${platform}`} title={name}
			>
				{platform === "beatport" &&
					<img className="platform-logo" src={`/assets${logoBeatport}`} alt="Logo Beatport" />
				}
				{platform === "amazon" &&
					<img className="platform-logo" src={`/assets${logoAmazon}`} alt="Logo Amazon" />
				}
				{platform === "deezer" &&
					<img className="platform-logo" src={`/assets${logoDeezer}`} alt="Logo Deezer" />
				}
				{platform === "spotify" &&
					<img className="platform-logo" src={`/assets${logoSpotify}`} alt="Logo Spotify" />
				}
				{platform === "youtube" &&
					<img className="platform-logo" src={`/assets${logoYoutube}`} alt="Logo Youtube" />
				}
				{platform === "appleMusic" &&
					<img className="platform-logo" src={`/assets${logoAppleMusic}`} alt="Logo Apple Music" />
				}
				{dropdown && name &&
					<span className="w-full md:w-auto h-full flex felx-col relative items-center md:items-start">
						{openOnPlatform &&
							openOnPlatform
						}
						{name}
					</span>
				}
				<img className="open-external md:hidden" src={`/assets${openIcon}`} alt="Open new window Icon"></img>
			</a>
		);
	}
}

export default TrackLink;
