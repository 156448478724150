import React from "react";
import { Stream, StreamUrls } from "../Types/Stream";
import { Strings } from "../Utils/StringCollection";
import IconShow from "../../images/icons/ic_eye@4x.png";
import IconHide from "../../images/icons/ic_hide@4x.png";
import { TwitchEmbed } from "react-twitch-embed";
import { format } from "date-fns";
import FacebookEmbed from "./FacebookEmbed";
import YoutubeEmbed from "./YoutubeEmbed";
import "./EventStream.css";
import { EventBanner } from "./EventBanner";

type Props = {
	latestStream: Stream;
	artist: string;
};

type State = {
	show: boolean;
};

class EventStream extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
			show: true,
		};
	}

	componentDidMount() {}

	getFormattedDate(dateString: string) {
		return format(new Date(dateString), "d. LLL Y");
	}

	getChannelName(url: string) {
		let urlSplit = url.match(
			/^((http[s]?|ftp):\/)?\/?([^:/\s]+)(:([^/]*))?((\/[\w/-]+)*\/)([\w\-.]+[^#?\s]+)(\?([^#]*))?(#(.*))?$/i
		);
		return urlSplit && urlSplit[8] ? urlSplit[8] : null;
	}

	renderStream(streamUrls: StreamUrls) {
		if ("twitch" in streamUrls && streamUrls.twitch) {
			return (
				<TwitchEmbed
					channel={this.getChannelName(streamUrls.twitch)}
					id={this.getChannelName(streamUrls.twitch)}
					theme="dark"
					width="100%"
					muted
					onVideoPause={() => console.log(":(")}
				/>
			);
			// } else if ("yt" in streamUrls && streamUrls.yt) {
			// 	return <YoutubeEmbed url={streamUrls.yt} />;
			// } else if ("fb" in streamUrls && streamUrls.fb) {
			// 	return <FacebookEmbed url={streamUrls.fb} />;
		} else {
			return null;
		}
	}

	isNowLive(start: string, end: string) {
		let now = new Date();
		if (start !== undefined && end !== undefined) {
			let startTime = new Date(start);
			let endTime = new Date(end);

			if (startTime <= now && endTime >= now) {
				return true;
			} else {
				return false;
			}
		}
	}

	render() {
		let startDate = this.props.latestStream?.start_time_utc;
		let endDate = this.props.latestStream?.end_time_utc;
		const streamUrls = this.props.latestStream?.streamUrls;

		const liveStream = this.renderStream(streamUrls || {});

		return !liveStream ? (
			<EventBanner stream={this.props.latestStream} />
		) : (
			<div className="container mx-auto event-stream-container bg-greyUltraDark md:bg-dark">
				{startDate && endDate && (
					<div
						className={
							"event-stream-wrapper md:border-b md:border-greyVeryDark "
						}
					>
						<div
							className={
								"live-video-container " +
								(this.isNowLive(startDate, endDate) ? "show" : "hide")
							}
						>
							<div className="button-container flex justify-start md:justify-end">
								<button
									onClick={() => {
										this.setState({ show: !this.state.show });
									}}
									className="text-primary font-medium bg-transparent border-none flex cursor-pointer"
								>
									{this.state.show ? Strings.HideButton : Strings.ShowButton}
									{this.state.show ? (
										<img src={`/assets${IconHide}`} alt="Icon Hide" />
									) : (
										<img src={`/assets${IconShow}`} alt="Icon Show" />
									)}
								</button>
							</div>

							{
								<div
									className={
										"video-container " + (this.state.show ? "show" : "hide")
									}
								>
									{liveStream}
								</div>
							}
						</div>

						{!this.isNowLive(startDate, endDate) && (
							<div className="not-live-info text-greyMiddle text-baseNoLiveInfoMobile md:text-baseNoLiveInfo">
								<p>
									{Strings.Offline} - {this.props.artist} {Strings.NotLive}
								</p>
								<p>{Strings.LastTracklist}</p>
							</div>
						)}

						<div className="data">
							<div
								className={
									"now-live flex items-center " +
									(this.isNowLive(startDate, endDate) ? "show" : "hide")
								}
							>
								<p className="text-primary m-0">{Strings.NowLive}</p>
							</div>
							<div className="event-name font-palanquin text-h2 font-bold mb-4 md:mb-2">
								<span>{this.props.latestStream?.event_name}</span>
								{!this.isNowLive(startDate, endDate) && (
									<span className="event-date">
										- {this.getFormattedDate(startDate)}
									</span>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default EventStream;
