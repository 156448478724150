import React from "react";
import { Stream } from "../Types/Stream";
import "./PlaylistButton.css";
import { observer } from "mobx-react";
import { SpotifyStore } from "../Stores/SpotifyStore";
import PlaylistButton from "./PlaylistButton";


type Props = {
	currentStream: Stream,
	currentStreamId?: string,
	currentSpotify: SpotifyStore,
};

type State = {
};

@observer
class Playlists extends React.Component<Props, State> {
	playlists: any;

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};

	}

	render() {
		const spotifyStore = this.props.currentSpotify;

		return (
			<>
				{ spotifyStore.playlists &&
					spotifyStore.playlists.map((playlist, index) => {
						if (playlist.eventId === this.props.currentStreamId) {
							if (playlist.url !== undefined) {
								return (
									<PlaylistButton
										key={index}
										url={playlist.url}
									/>
								)
							} else {
								return false;
							}
						} else {
							return false;
						}
					})
				}
			</>
		);
	}
}

export default Playlists;
