import React from 'react';
import { makeObservable, observable } from "mobx";
import { observer } from 'mobx-react';
import { StreamsStore } from "./Stores/StreamsStore";
import EventWrapper from './Components/EventWrapper';
import LoadingIndicator from './Components/LoadingIndicator';
import HeaderArtistWrapper from './Components/HeaderArtistWrapper';
import Footer from './Components/Footer';
import { SpotifyStore } from './Stores/SpotifyStore';
import NoEvent from './Components/NoEvent';
import { Banner } from './Types/Banner';
import { Strings } from './Utils/StringCollection';

type Props = {
	artistId: string;
	artistName: string;
	artistImage?: string;
	banner?: string;
};

type State = {
	isLoading: boolean;
	tabIndex: number;
}

@observer
class App extends React.Component<Props, State> {
	isLoading: boolean = true;
	streamsStore: StreamsStore;
	// artistId = this.getArtistId();
	spotifyStore: SpotifyStore;
	loading: boolean = false;
	bannerContent: Array<Banner> = [];

	constructor(props: Props) {
		super(props);
		this.state = {
			isLoading: true,
			tabIndex: 0,

		}

		makeObservable(this, {
			isLoading: observable,
			spotifyStore: observable,
		});

		this.streamsStore = new StreamsStore(this.props.artistId);
		this.spotifyStore = new SpotifyStore();
	}

	getArtistId() {
		let location = window.location;
		let params = (new URL(location.toString())).searchParams;
		let id = params.get("artistId");

		if (params && id !== "" && id !== null) {
			return id;
		} else {
			return this.props.artistId
		}
	}


	render() {
		if (this.props.banner) {
			this.bannerContent = JSON.parse(this.props.banner);
		}

		return (
			<div className="App app bg-dark text-light min-h-screen font-workSans text-base font-normal">
				<div className="header">
					<HeaderArtistWrapper
						artistId={this.props.artistId}
						artistName={this.props.artistName}
						artistImage={this.props.artistImage}
						currentStream={this.streamsStore.currentStream}
						streamsStore={this.streamsStore}
						currentSpotify={this.spotifyStore}
						streamStore={this.streamsStore}
					/>
				</div>
				<div className="body">
					{this.streamsStore.isLoading ?
						<LoadingIndicator />
						:
						<div>
							{(this.streamsStore.currentStream && this.streamsStore.currentStream?.start_time_utc
								&& this.streamsStore.currentStream.performingArtist) &&
								<EventWrapper
									currentStream={this.streamsStore.currentStream}
									startTime={this.streamsStore.currentStream.start_time_utc}
									artist={this.streamsStore.currentStream.performingArtist}
									artistId={this.props.artistId}
									currentSpotify={this.spotifyStore}
									banner={this.bannerContent}
									streamStore={this.streamsStore}
								/>
							}
						</div>
					}
					{(!this.streamsStore.currentStream && this.streamsStore.loaded === true) &&
						<NoEvent
							message={Strings.NoEvent}
						/>
					}
				</div>
				<Footer />
			</div>
		)
	}
}

export default App;
