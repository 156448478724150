import * as React from "react";
import { Stream } from "../Types/Stream";

export const EventBanner = ({ stream }: { stream: Stream }) => (
	<div className="relative">
		<div
			className="bg-cover bg-center"
			style={{ backgroundImage: `url(${stream.eventBanner})` }}
		>
			<img
				src={stream.eventBanner}
				style={{ visibility: "hidden", maxWidth: "inherit" }}
			/>
			<div className="absolute bottom-6 left-6 md:bottom-12 md:left-12 z-10">
				<h1 className="font-bold text-h1 md:text-eventBanner text-primary">
					{stream.performingArtist}
				</h1>
				<span className="relative left-4 font-bold text-h3 md:text-h2">
					live @ {stream.event_name?.trim()}, {stream.event_country}
				</span>
			</div>
		</div>
		<div
			className="absolute bottom-0 w-full"
			style={{
				height: "60%",
				background: "linear-gradient(transparent 0%,rgba(0,0,0,1) 100%)",
			}}
		></div>
	</div>
);
