import React from "react";
import { Strings } from '../Utils/StringCollection';
import { observer } from "mobx-react";
import logoSpotify from "../../images/icons/logo_spotify.svg";
import logoBeatport from "../../images/icons/logo_beatport.svg";
import openIcon from "../../images/icons/ic_external@2x.png";


type Props = {
	url: string,
	platform: string,
	classname: string,
};

type State = {
};

@observer
class OpenPlaylist extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};
	}

	componentDidMount() { }

	openPlaylist = (url: string) => {
		window.open(url, "_blank")
	}

	render() {
		const classname = this.props.classname.toLowerCase();

		return (
			<a
				className={classname + " open-playlist cursor-pointer relative flex items-center"}
				href={this.props.url}
				target="_blank"
				rel="noreferrer"
			>
				{classname === "spotify" &&
					<img src={`/assets${logoSpotify}`} alt="Logo Spotify"></img>
				}
				{classname === "beatport" &&
					<img src={`/assets${logoBeatport}`} alt="Logo Beatport"></img>
				}
				<span className="span-container w-full h-full md:h-auto md:w-auto flex flex-row relative items-center md:items-start">
					<span className="pr-1.5">{Strings.PlaylistButton}</span>
					<span>{this.props.platform}</span>
				</span>
				<img className="open-external md:hidden" src={`/assets${openIcon}`} alt="Open new window Icon"></img>
			</a>
		);
	}
}

export default OpenPlaylist;
