import { observer } from "mobx-react";
import React from "react";
import { Stream } from "../Types/Stream";
import { FeedStore } from "../Stores/FeedStore";
import { Strings } from "../Utils/StringCollection";
import NoTrack from "./NoTrack";
import HeaderCurrentTrack from "./HeaderCurrentTrack";
import { makeObservable, observable } from "mobx";
import uiFunction from "../Utils/HelperFunctions";
import { SpotifyStore } from "../Stores/SpotifyStore";
import { TrackLinks } from "./TrackLinks";

type Props = {
	currentStream: Stream,
	artistId: string,
	currentFeed: FeedStore,
	currentSpotify: SpotifyStore,
};

type State = {
	touch: boolean;
};


@observer
class HeaderTrackWrapper extends React.Component<Props, State> {
	@observable trackWrapper: any;
	@observable trackName: any;
	@observable animateTitle: boolean = false;

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
			touch: false,
		};

		makeObservable(this, {
			animateTitle: observable,
			trackWrapper: observable,
			trackName: observable,
		});

		this.trackWrapper = React.createRef();
		this.trackName = React.createRef();
	}

	componentDidMount() {
		this.animateTitle = uiFunction.checkTrackNameAnimation(this.trackWrapper, this.trackName);
	}

	componentDidUpdate() {
		this.animateTitle = uiFunction.checkTrackNameAnimation(this.trackWrapper, this.trackName);
	}

	handleTouch = (e: any) => {
		this.setState({ touch: true });
	};

	cancelTouch = (e: any) => {
		this.setState({ touch: false });
	};


	render() {
		const track = this.props.currentFeed.newestTrack;

		return (
			<>
				{typeof this.props.currentStream !== "undefined" && typeof this.props.currentFeed !== "undefined" &&
					this.props.currentStream && this.props.currentFeed.tracklist &&
					<div className="header-track-wrapper flex items-center md:justify-between">
						{this.props.currentFeed.tracklist && this.props.currentFeed.tracklist.length > 0 &&
							<div className="track-display flex items-center relative overflow-hidden">
								{track && track.lastPlay?.cover &&
									<span className="image-container flex justify-center items-center border border-primary rounded-md bg-greyUltraDark">
										<img className="cover rounded-md" src={track.lastPlay?.cover} alt={`${track.lastPlay?.artistName}`} />
									</span>
								}
								<div
									className="current-track flex flex-col md:flex-row items-start md:items-center"
								>
									<div className="live-container whitespace-nowrap bg-greyUltraDark" style={{ zIndex: 2 }}>
										<p className="now-playing text-primary flex items-center font-medium mr-2">{Strings.NowPlaying}</p>
									</div>
									<div
										className={`scrollable-container flex items-center relative overflow-hidden ${this.state.touch ? 'touch' : ''}`}
										onTouchStart={this.handleTouch}
										onTouchEnd={this.cancelTouch}
										onTouchCancel={this.cancelTouch}
										ref={this.trackWrapper}
									>
										<div
											className={`scrollable-track inline-flex whitespace-nowrap ${this.animateTitle ? 'animate' : ''}`}
											ref={this.trackName}
										>
											{track && track.lastPlay?.artistName &&
												<p className="artist font-medium">{track.lastPlay?.artistName} - </p>
											}
											{track && track.lastPlay?.name &&
												<p className="title font-medium">
													{track.lastPlay?.name}
												</p>
											}
											{track && track.lastPlay?.mix &&
												<span className="mix font-medium">({track.lastPlay?.mix})</span>
											}
											{track && track.lastPlay?.label &&
												<span className="label font-medium">[{track.lastPlay?.label}]</span>
											}
										</div>
									</div>
								</div>
							</div>
						}

						{this.props.currentFeed.tracklist.length > 0 ?
							<>
								{this.props.currentFeed.tracklist.map((track, index) => {
									if (index === 0) {
										return (
											<TrackLinks
												track={track}
												key={`${index}-track`}
											/>
										)
									} else {
										return false;
									}
								})}
							</>
							:
							<NoTrack message={Strings.NoTrackDetected} insideHeader={true} />
						}
					</div>
				}
			</>
		);
	}
}

export default HeaderTrackWrapper;
