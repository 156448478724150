import React from "react";
import { Stream } from "../Types/Stream";
import "./Lineup.css";
import { Strings } from "../Utils/StringCollection";
import { Swiper, SwiperSlide } from "swiper/react";
import _ from "lodash";
import "swiper/swiper-bundle.min.css";
import uiFunction from "../Utils/HelperFunctions";
import { FeedStore } from "../Stores/FeedStore";
import { observer } from "mobx-react";
import { SpotifyStore } from "../Stores/SpotifyStore";

type Props = {
	latestStream?: Stream;
	currentFeed: FeedStore;
	currentSpotify: SpotifyStore;
};

type State = {
	noArtistID: boolean;
};

@observer
class Lineup extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
			noArtistID: true,
		};
	}

	componentDidMount() {}

	getTime(dateString: string) {
		let date = new Date(dateString);
		let hours = (date.getHours() < 10 ? "0" : "") + date.getHours();
		let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
		let time = hours + ":" + minutes;
		return time.toString();
	}

	isNowLive(start: string, end: string) {
		let now = new Date();
		if (start !== undefined && end !== undefined) {
			let startTime = new Date(start);
			let endTime = new Date(end);

			if (startTime <= now && endTime >= now) {
				return true;
			} else {
				return false;
			}
		}
	}

	updateCurrentArtistId(artistId: string) {
		this.props.currentFeed.currentArtistId = artistId;
		this.props.currentSpotify.currentArtistId = artistId;
		this.props.currentSpotify.setPlaylists(artistId);
	}

	updateSelectedArtistId(artistId: string) {
		this.props.currentFeed.selectedArtistId = artistId;
		this.props.currentSpotify.setPlaylists(artistId);
	}

	render() {
		const lineup = this.props.latestStream?.lineup_utc || undefined;
		const sortedLineupData = _.orderBy(lineup, ["time"], ["asc"]);
		const nowLive = _.last(
			uiFunction.getCurrentPlayingArtist(sortedLineupData)
		);
		let startDate = this.props.latestStream?.start_time_utc;
		let endDate = this.props.latestStream?.end_time_utc;
		let startSlideIndex = sortedLineupData.findIndex((stream, index) => {
			if (stream.artistID === nowLive?.artistID) {
				return index;
			} else {
				return 0;
			}
		});

		return (
			<div className="lineup-container overflow-hidden relative pb-14">
				<div className="container mx-auto">
					<div className="title-container">
						<span className="text-greyLightHeader font-medium">
							{Strings.LineUpTitle}
						</span>
					</div>

					<div className="lineup-wrapper">
						<Swiper
							spaceBetween={15}
							slidesPerView={"auto"}
							watchSlidesVisibility
							initialSlide={startSlideIndex}
						>
							{sortedLineupData &&
								sortedLineupData.map((stream, i) => {
									if (
										this.props.currentFeed.selectedArtistId === "" &&
										stream.artistID &&
										nowLive?.artist === stream.artist
									) {
										this.updateSelectedArtistId(stream.artistID);
									}
									return (
										<SwiperSlide
											className={`w-auto ${
												nowLive?.artist === stream.artist ? "current" : ""
											} ${
												this.props.currentFeed.selectedArtistId ===
												stream.artistID
													? "selected"
													: ""
											}`}
											key={i}
										>
											{stream.artistID && (
												<div
													className="box flex items-center bg-greyUltraDark w-full p-4 font-medium rounded-md"
													onClick={() => {
														if (stream.artistID) {
															this.updateCurrentArtistId(stream.artistID);
															this.updateSelectedArtistId(stream.artistID);
														}
													}}
												>
													{nowLive?.artist === stream.artist &&
														startDate &&
														endDate &&
														stream.time && (
															<p className="now-live relative mr-6">
																{this.isNowLive(startDate, endDate)
																	? Strings.NowLive
																	: this.getTime(stream.time)}
															</p>
														)}
													{stream.time && nowLive?.artist !== stream.artist && (
														<p className="time text-greyLightHeader">
															{this.getTime(stream.time)}
														</p>
													)}
													{stream.picture && (
														<img
															className="rounded-full block object-cover"
															src={stream.picture}
															alt={stream.artist}
														></img>
													)}
													{stream.artist && (
														<p className="artist text-light">{stream.artist}</p>
													)}
												</div>
											)}
										</SwiperSlide>
									);
								})}
						</Swiper>
					</div>
				</div>
			</div>
		);
	}
}

export default Lineup;
