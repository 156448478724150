import React from "react";
import TrackElement from "./TrackElement";
import "./Tracklist.css";
import { Strings } from '../Utils/StringCollection';
import NoTrack from "./NoTrack";
import { FeedStore } from "../Stores/FeedStore";
import { observer } from "mobx-react";
import { Track } from "../Types/Track";
import PromoBanner from "./PromoBanner";
import { Banner } from "../Types/Banner";


type Props = {
	currentFeed: FeedStore;
	banner?: Array<Banner>;
};

type State = {
	openRowIndex: number,
};

@observer
class Tracklist extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
			openRowIndex: 0
		};
	}

	componentDidMount() { }

	toggleOpen = (idx: number) => {
		this.setState({ openRowIndex: idx });
	}

	everyEightNumber = (array: Array<Number>, nth: number) => {
		let newArray = array.filter((e, i) => {
			return (i % nth === nth - 1);
		})

		return newArray;
	}

	render() {
		let tracklist = this.props.currentFeed.tracklist;
		let currentTracks: Array<Track> = tracklist.filter((track) => {
			return track.lastPlay?.performingArtistID === this.props.currentFeed.selectedArtistId;
		});
		const currentFeed = this.props.currentFeed;
		let count = 0;
		let bannerCount = 4;
		let countArray: Array<Number> = [];

		return (
			<div id="full-tracklist" className="tracklist" style={{ paddingBottom: "50px" }}>
				<div className="table-head flex items-center text-greyLightHeader font-medium container mx-auto">
					<div className="track w-full">
						{Strings.TableHeadTitle}
					</div>
					<div className="artist">
						{Strings.TableHeadArtist}
					</div>
					<div className="label">
						{Strings.TableHeadLabel}
					</div>
				</div>

				{currentTracks.length > 0 ?
					<>
						{currentTracks.length >= 4 &&
							tracklist.map((track, index) => {
								if (track.lastPlay?.performingArtistID === currentFeed.selectedArtistId) {
									const currentTrackId = track.id;
									const newestTrackId = currentFeed.newestTrack?.id;
									let isCurrent = (currentTrackId !== undefined && newestTrackId !== undefined) ? currentTrackId === newestTrackId : false;
									count++;
									bannerCount++;

									countArray.push(bannerCount);

									let bannerCountArray = this.everyEightNumber(countArray, 8);

									return (
										<div key={`${index}-trackElement`}>
											<TrackElement
												track={track}
												current={isCurrent ? "current" : ""}
												key={`${index}-track`}
												isOpen={this.state.openRowIndex === index}
												onClick={() => this.toggleOpen(index)}
											/>
											{count === 4 &&
												<>
													{this.props.banner && this.props.banner.length > 0 &&
														<PromoBanner
															key={`${index}-banner`}
															content={this.props.banner}
														/>
													}
												</>
											}
											{bannerCountArray.length > 0 &&
												<>
													{bannerCountArray.map((bannerindex) => {
														if (count === bannerindex && bannerindex >= 8) {
															if (this.props.banner && this.props.banner.length > 0) {
																return <PromoBanner
																	key={`${index}-banner`}
																	content={this.props.banner}
																/>
															}
														}
													})
													}
												</>
											}
										</div>
									)
								} else {
									return false;
								}
							})
						}

						{/* Fix Banner until 4 tracks exist */}
						{currentTracks.length < 4 &&
							<>
								{tracklist.map((track, index) => {
									if (track.lastPlay?.performingArtistID === currentFeed.selectedArtistId) {
										const currentTrackId = track.id;
										const newestTrackId = currentFeed.newestTrack?.id;
										let isCurrent = (currentTrackId !== undefined && newestTrackId !== undefined) ? currentTrackId === newestTrackId : false;
										count++;

										return (
											<div key={`${index}-trackElement`}>
												<TrackElement
													track={track}
													current={isCurrent ? "current" : ""}
													key={`${index}-track`}
													isOpen={this.state.openRowIndex === index}
													onClick={() => this.toggleOpen(index)}
												/>
											</div>
										)
									}
								})}

								{this.props.banner && this.props.banner.length > 0 &&
									<PromoBanner
										key={`banner`}
										content={this.props.banner}
									/>
								}
							</>
						}

					</>
					:
					<>
						<NoTrack message={Strings.NoTrackDetected} insideHeader={false} />
					</>
				}
			</div>
		);
	}
}

export default Tracklist;
