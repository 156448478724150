import React from "react";
import { Stream } from "../Types/Stream";
import { Strings } from '../Utils/StringCollection';
import "./PlaylistButton.css";
import { observer } from "mobx-react";
import SpotifyLogo from "../../images/icons/logo_spotify.svg";


type Props = {
	latestStream?: Stream,
	url: string,
};

type State = {
};

@observer
class PlaylistButton extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {};
	}

	componentDidMount() { }

	openSpotify = (url: string) => {
		window.open(url, "_blank")
	}

	render() {
		return (
			<div className="playlist-button container mx-auto flex justify-end">
				<div
					className="playlist-container cursor-pointer relative bg-transparent text-light border rounded-md border-greyVeryDark flex uppercase font-semibold tracking-spotifyButton shadow-none text-dark items-center"
					onClick={() => this.openSpotify(this.props.url)}
				>
					{Strings.PlaylistButton}
					<button
						className="spotify relative"
					>
						<img src={`/assets${SpotifyLogo}`} alt="Spotify Logo" />
					</button>
				</div>
			</div>
		);
	}
}

export default PlaylistButton;
