import React from "react";
import "./LoadingIndicator.css";


class LoadingIndicator extends React.Component {

	render() {
		return (
			<div
				className="loading-indicator"
				style={{
					display: "flex"
				}}
			>
				<div className="indicator"></div>
			</div>
		);
	}
}

export default LoadingIndicator;
