const TabCurrentEvent = "Live Event";
const TabPreviousEvents = "Previous Events";
const TableHeadTitle = "Track (Newest first)";
const TableHeadArtist = "Artist";
const TableHeadLabel = "Label";
const ShowButton = "Show stream";
const HideButton = "Hide stream";
const LineUpTitle = "Event Lineup";
const NoTrackMessage = "Waiting for music";
const NowLive = "Live";
const Beatport = "Beatport";
const Spotify = "Spotify";
const AmazonMusic = "Amazon Music";
const YoutubeMusic = "Youtube Music";
const AppleMusic = "Apple Music";
const Deezer = "Deezer";
const ShowTracklist = "See full tracklist";
const NowPlaying = "Live TrackID";
const PlaylistButton = "Open playlist on";
const HeaderBrand = "seeqnc";
const HeaderClaim = "We make music visible";
const ViewArtistOnFb = "View artist on facebook";
const DownloadTrack = "Download track";
const NoTrackDetected = "Sorry, no song detected yet";
const Copyright = "© seeqnc 2020. All rights reserved";
const OpenOnPlatform = "Open on ";
const CopyLinkClipboard = "Copy share link to clipboard";
const CopyLink = "Copy Link";
const Share = "Share";
const WhatsApp = "WhatsApp";
const Messages = "Messages";
const NoEvent = "Currently this artist has no live event.";
const Offline = "Offline";
const NotLive = "is currently not live.";
const LastTracklist = "Check out their last tracklist.";

const Strings = {
	TabCurrentEvent,
	TabPreviousEvents,
	TableHeadTitle,
	TableHeadArtist,
	TableHeadLabel,
	ShowButton,
	HideButton,
	LineUpTitle,
	NoTrackMessage,
	NowLive,
	Beatport,
	Spotify,
	AmazonMusic,
	YoutubeMusic,
	Deezer,
	ShowTracklist,
	NowPlaying,
	PlaylistButton,
	HeaderBrand,
	HeaderClaim,
	ViewArtistOnFb,
	DownloadTrack,
	NoTrackDetected,
	Copyright,
	OpenOnPlatform,
	CopyLinkClipboard,
	CopyLink,
	Share,
	WhatsApp,
	Messages,
	NoEvent,
	Offline,
	NotLive,
	LastTracklist,
	AppleMusic,
};
export { Strings };
