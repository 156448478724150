import React from "react";
import { Strings } from '../Utils/StringCollection';
import { observer } from "mobx-react";
import CopyToClipboard from "react-copy-to-clipboard";
import ShareWhatsApp from "./ShareWhatsApp";
import ShareMessages from "./ShareMessages";
import shareIcon from "../../images/icons/ic_share@4x.png";
import copyIcon from "../../images/icons/ic_link.png";
import closeIcon from "../../images/icons/close.png";


type Props = {

};

type State = {
	dropdownOpen: boolean;
	copied: boolean;
};

@observer
class Share extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
			dropdownOpen: false,
			copied: false,
		};
	}

	componentDidMount() { }

	onCopy = () => {
		this.setState({ copied: true });
	};

	render() {
		let pageLink = window.location.href;

		return (
			<div className="share dropdown-header relative w-full">
				<div className="dropdown-header-link relative flex items-center bg-greyUltraDark w-full" onClick={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}>
					<img src={`/assets${shareIcon}`} alt="Icon Share"></img>
					<span className="span-underline w-full h-full flex items-center relative">
						{Strings.Share}
					</span>
				</div>
				<div className={`dropdown-header-container ${this.state.dropdownOpen ? "open" : ""}`}>
					<div className={`dropdown-header-content w-full rounded-md z-50 ${this.state.dropdownOpen ? "open" : ""}`}>
						<div className="dropdown-header flex md:hidden justify-end items-center p-4 bg-greyVeryDark border border-r-0 border-l-0 border-greyVeryDark">
							<img src={`/assets${shareIcon}`} alt="Icon Share"></img>
							<span className="w-full h-full flex items-center relative">
								{Strings.Share}
							</span>
							<button
								className="close-button bg-transparent p-0 flex justify-center items-center border-none"
								onClick={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}
							>
								<img src={`/assets${closeIcon}`} alt="Seeqnc Logo"></img>
							</button>
						</div>

						<ShareMessages
							pageLink={pageLink}
						/>

						<ShareWhatsApp
							pageLink={pageLink}
						/>

						<CopyToClipboard
							text={pageLink}
							onCopy={this.onCopy}
						>
							<a
								className="copy-link copy-link-mobile cursor-pointer relative flex items-center"
							>
								<img src={`/assets${copyIcon}`} alt="Copy Link Icon"></img>
								<span className="w-full md:w-auto flex flex-col relative items-start h-full justify-center">
									{Strings.CopyLink}
								</span>
							</a>
						</CopyToClipboard>
					</div>
				</div>
			</div>
		);
	}
}

export default Share;
