import { Lineup } from "../Types/Stream";

const uiFunction = {
	getCurrentPlayingArtist(lineup: Lineup[] | undefined) {
		// const currentTimestamp = 1599968700; // Test with hardcoded timestamps
		const currentTimestamp = Math.floor(Date.now() / 1000);

		return lineup?.filter((lData) => lData.time && currentTimestamp > (Date.parse(lData.time) / 1000));

	},

	checkTrackNameAnimation(trackWrapper: any, trackName: any) {
		if ((trackWrapper && trackName && trackWrapper.current && trackName.current)) {
			let trackWrapperWidth = trackWrapper.current.clientWidth;
			let trackNameWidth = trackName.current.clientWidth;

			if (trackWrapperWidth && trackNameWidth && (trackNameWidth >= trackWrapperWidth)) {
				return true;
			}
		}
		return false;
	},
}

export default uiFunction;