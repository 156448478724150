import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import "./PromoBanner.css";
import iconArrow from "../../images/icons/ic_arrow_forward.png";
import { Banner } from "../Types/Banner";

type Props = {
	content: Array<Banner>;
};


class PromoBanner extends React.Component<Props> {

	render() {
		return (
			<div className="promo-banner overflow-hidden relative">
				<div className="container mx-auto">
					{this.props.content &&
						<Swiper
							spaceBetween={15}
							slidesPerView={'auto'}
							watchSlidesVisibility
						>
							{this.props.content && this.props.content.length &&
								this.props.content.map((banner, index) => {
									return (
										<SwiperSlide
											className="promo-banner-slide"
											key={`${index}-bannerSlide`}
										>
											<a
												className="promo-banner-content flex rounded-md bg-greyUltraDark"
												href={banner.link}
												target="_blank"
												rel="noreferrer"
											>
												{banner.image && banner.headline &&
													<img className="rounded-md rounded-r-none" src={banner.image} alt={banner.headline}></img>
												}
												<div className="promo-banner-text">
													{banner.headline &&
														<h2 className="text-greyLightHeader text-base">{banner.headline}</h2>
													}
													{banner.text &&
														<p className="text-base font-medium">{banner.text}</p>
													}
												</div>
												<div className="promo-banner-arrow border border-t-0 border-r-0 border-b-0 border-greyVeryDark flex justify-center items-center">
													<img src={`/assets${iconArrow}`} alt="Icon Arrow Forward"></img>
												</div>
											</a>
										</SwiperSlide>
									)
								})
							}
						</Swiper>
					}
				</div>
			</div>
		);
	}
}

export default PromoBanner;
