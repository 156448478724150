import { makeObservable, observable } from "mobx";
import { firebaseProvider } from "../Provider/firebaseProvider";
import { Spotify } from "../Types/Spotify";


class SpotifyStore {
	playlists?: Array<Spotify>;
	currentArtistId: string = "";
	unsubscribedCurrentFirebaseObserver: () => void = () => { };

	constructor() {
		makeObservable(this, {
			playlists: observable,
			currentArtistId: observable,
		});

	}

	setPlaylists(currentArtistId: string) {
		this.unsubscribedCurrentFirebaseObserver();
		this.currentArtistId = currentArtistId;

		this.unsubscribedCurrentFirebaseObserver = firebaseProvider.firestore.collection("playlists")
			.doc(this.currentArtistId).collection("spotify")
			.onSnapshot(observer => {
				var playlistResult: Array<Spotify> = [];
				observer.forEach(document => {
					const result = document.data();

					if (result) {
						playlistResult.push(result);
					}
				});

				this.playlists = playlistResult;
				return this.playlists;
			});
	}
}

export { SpotifyStore }