import React from 'react';
import { Stream } from "../Types/Stream";
import { SpotifyStore } from '../Stores/SpotifyStore';
import OpenPlaylist from './OpenPlaylist';
import { Strings } from '../Utils/StringCollection';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Share from './Share';
import moreButtonIcon from "../../images/icons/ic_more@4x_white.png";
import copyIcon from "../../images/icons/ic_link.png";
import closeIcon from "../../images/icons/close.png";
import { observer } from 'mobx-react';

type Props = {
	currentStream?: Stream,
	currentSpotify: SpotifyStore,
	currentStreamId?: string;
};

type State = {
	dropdownOpen: boolean;
	copied: boolean;
}

@observer
class HeaderMoreButton extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			dropdownOpen: false,
			copied: false,
		};
	}

	onCopy = () => {
		this.setState({ copied: true });
	};

	toggleDropdownContent = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen })
		let fixedContainer = document.getElementById("fixed-container");

		if (!this.state.dropdownOpen) {
			document.body.classList.add("no-scroll");
			fixedContainer?.classList.add("z-index");
		} else {
			document.body.classList.remove("no-scroll");
			fixedContainer?.classList.remove("z-index");
		}
	}

	render() {
		let pageLink = window.location.href;

		return (
			<div className="header-more-button">
				<div className="dropdown-header relative">
					<div className="dropdown-header-icon relative flex items-center justify-center bg-greyVeryDark rounded-full" onClick={this.toggleDropdownContent}>
						<img src={`/assets${moreButtonIcon}`} alt="More Button Icon"></img>
					</div>
					<div className={`dropdown-header-container ${this.state.dropdownOpen ? "open" : ""}`}>
						<div className={`dropdown-header-content w-full rounded-md z-50 ${this.state.dropdownOpen ? "open" : ""}`}>
							<div className="dropdown-header flex md:hidden justify-end items-center p-4 bg-greyVeryDark border border-r-0 border-l-0 border-greyVeryDark">
								<button
									className="close-button bg-transparent p-0 flex justify-center items-center border-none"
									onClick={this.toggleDropdownContent}
								>
									<img src={`/assets${closeIcon}`} alt="Close Icon"></img>
								</button>
							</div>

							<div className="flex md:hidden">
								<Share />
							</div>

							<div className="hidden md:flex">
								<CopyToClipboard
									text={pageLink}
									onCopy={this.onCopy}
								>
									<a
										className="copy-link cursor-pointer relative flex items-center"
									>
										<img src={`/assets${copyIcon}`} alt="Copy Link Icon"></img>
										<span className="w-full md:w-auto flex flex-col relative items-start">
											{Strings.CopyLinkClipboard}
										</span>
									</a>
								</CopyToClipboard>
							</div>

							{this.props.currentSpotify.playlists &&
								this.props.currentSpotify.playlists.map((playlist, index) => {
									if (playlist.eventId === this.props.currentStreamId) {
										if (playlist.url !== undefined) {
											return (
												<OpenPlaylist
													key={index}
													url={playlist.url}
													platform={Strings.Spotify}
													classname={Strings.Spotify}
												/>
											)
										} else {
											return false;
										}
									} else {
										return false;
									}
								})
							}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default HeaderMoreButton;
