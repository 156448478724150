import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById("artist-widget");
if (root) {
	ReactDOM.render(
		<React.StrictMode>
		{root?.dataset.artistId && root?.dataset.artistName &&
			<App 
			artistId={root.dataset.artistId}
			artistName={root.dataset.artistName}
			artistImage={root.dataset.artistImage}
			banner={root.dataset.banner} />
		}
	</React.StrictMode>, root
	);
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
