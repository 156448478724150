import React from "react";
import "./NoTrack.css";

type Props = {
	message: string;
	insideHeader: boolean;
};


class NoTrack extends React.Component<Props> {
	render() {
		return (
			// Loading Indicator
			// <div className="no-track-wrapper">
			// 	<div className="no-track-info">
			// 		<p className="loading">{this.props.message}</p>
			// 	</div>
			// </div>
			<div className={"no-track-container flex flex-col text-primary bg-greyUltraDark border border-r-0 border-l-0 border-greyVeryDark " + (this.props.insideHeader ? "" : "container mx-auto")}>
				<div className="no-track w-full flex items-center ">
					<p className="m-0 flex items-center">{this.props.message}</p>
				</div>
			</div>
		);
	}
}

export default NoTrack;
