import { observer } from "mobx-react";
import React from "react";
import { Stream } from "../Types/Stream";
import { FeedStore } from "../Stores/FeedStore";
import { SpotifyStore } from "../Stores/SpotifyStore";
import { makeObservable, observable } from "mobx";
import Lineup from "./Lineup";
import Tracklist from "./Tracklist";
import EventStream from "./EventStream";
import { compact } from "lodash";
import HeaderTrackWrapper from "./HeaderTrackWrapper";
import { Strings } from "../Utils/StringCollection";
import { Link } from "react-scroll";
import Playlists from "./Playlists";
import iconArrowDown from "../../images/icons/ic_arrow_down.svg";
import { Banner } from "../Types/Banner";
import NoEvent from "./NoEvent";
import { StreamsStore } from "../Stores/StreamsStore";

type Props = {
	currentStream: Stream;
	startTime: string;
	artist: string;
	artistId: string;
	currentSpotify: SpotifyStore;
	banner?: Array<Banner>;
	streamStore: StreamsStore;
};

type State = {
	isLoading: boolean;
};

@observer
class EventWrapper extends React.Component<Props, State> {
	@observable currentFeed: FeedStore;
	isLoading: boolean = true;
	artistIds: Array<string> = [];

	constructor(props: Props) {
		super(props);
		// set initial state in constructor directly
		this.state = {
			isLoading: true,
		};

		makeObservable(this, {
			currentFeed: observable,
		});

		this.artistIds = compact(
			this.props.currentStream.lineup_utc?.map((artist) => {
				return artist.artistID;
			}) || []
		);

		this.currentFeed = new FeedStore(
			this.artistIds,
			this.props.artistId,
			this.props.startTime
		);
	}

	isNowLive(start: string, end: string) {
		let now = new Date();
		if (start !== undefined && end !== undefined) {
			let startTime = new Date(start);
			let endTime = new Date(end);

			if (startTime <= now && endTime >= now) {
				return true;
			} else {
				console;
				return false;
			}
		}
	}

	render() {
		return (
			<>
				{!this.currentFeed.noFeed &&
					this.currentFeed.loaded === true &&
					this.props.currentStream &&
					this.props.currentStream.start_time_utc &&
					this.props.currentStream.end_time_utc &&
					this.currentFeed.tracklist.length > 0 && (
						<div
							className={
								"header-tracklist-container flex justify-between items-center text-light bg-greyUltraDark w-full sticky top-90 " +
								(this.isNowLive(
									this.props.currentStream.start_time_utc,
									this.props.currentStream.end_time_utc
								)
									? "show"
									: "hide")
							}
						>
							<div className="track-container">
								{this.props.currentStream && (
									<HeaderTrackWrapper
										currentStream={this.props.currentStream}
										artistId={this.props.artistId}
										currentFeed={this.currentFeed}
										currentSpotify={this.props.currentSpotify}
									/>
								)}
							</div>
							<div className="show-tracklist hidden md:flex md:justify-end">
								<Link
									to="full-tracklist"
									spy={true}
									smooth={true}
									offset={-180}
									className="text-primary bg-transparent flex font-medium border-none cursor-pointer"
								>
									<span>{Strings.ShowTracklist}</span>
									<img
										src={`/assets${iconArrowDown}`}
										alt="Icon Arrow Down"
									></img>
								</Link>
							</div>
						</div>
					)}
				{this.currentFeed.noFeed &&
					this.currentFeed.loaded &&
					this.currentFeed.tracklist.length === 0 && (
						<NoEvent message={Strings.NoEvent} />
					)}
				{typeof this.props.currentStream !== "undefined" &&
					typeof this.currentFeed !== "undefined" &&
					!this.currentFeed.noFeed &&
					this.props.currentStream &&
					this.currentFeed.tracklist && (
						<div className="event-wrapper">
							<EventStream
								latestStream={this.props.currentStream}
								artist={this.props.artist}
							/>

							{this.props.currentStream.lineup_utc &&
								this.props.currentStream.lineup_utc.length > 0 &&
								this.props.currentSpotify && (
									<Lineup
										latestStream={this.props.currentStream}
										currentFeed={this.currentFeed}
										currentSpotify={this.props.currentSpotify}
									/>
								)}

							{this.props.currentSpotify && (
								<div className="hidden md:block">
									<Playlists
										currentStream={this.props.currentStream}
										currentStreamId={this.props.streamStore.currentStreamId}
										currentSpotify={this.props.currentSpotify}
									/>
								</div>
							)}

							{this.currentFeed.tracklist &&
								this.currentFeed.tracklist.length > 0 && (
									<Tracklist
										currentFeed={this.currentFeed}
										banner={this.props.banner}
									/>
								)}
						</div>
					)}
			</>
		);
	}
}

export default EventWrapper;
